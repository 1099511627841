<template>
  <div class="plugin layout-vertical" style="height: 100vh;">
    <div class="layout-horizontal layout-middle padding-horizontal-large padding-vertical layout-inflexible">
      <back-button @click="$router.back()"></back-button>
      <div class="font-size-medium margin-left font-weight-bold">插件</div>
    </div>
    <div class="layout-flexible" style="overflow: hidden">
      <ys-infinite-scroll-view @loadmore="loadMore" style="height: 100%;">
        <div class="padding">
          <div class="card card-plugin-info" v-if="plugin" style="padding: 0px;">
            <div class="padding padding-large" style="background-color: #faf4f5">
              <div class="font-size-small">插件信息</div>
              <div class="layout-horizontal padding-vertical">
                <div class="plugin-icon layout-inflexible">
                  <i class="fas fa-plug font-size-large"></i>
                </div>
                <div class="margin-left layout-inflexible">
                  <div class="font-size-medium">
                    <span class="font-weight-bold">{{ plugin.tag }}</span>
                    <span class="font-color-secondary margin-left-small">{{ plugin.name }}</span>
                    <span class="operations margin-left">
                    <ys-button lighting round size="small">
                      <i class="fas fa-pen-alt"></i>
                    </ys-button>
                  </span>
                  </div>
                  <div class="font-color-secondary margin-top">
                    <span class="mark-state mark-start" v-if="plugin.online">运行中 <i class="fas fa-play"></i></span>
                    <span class="mark-state mark-stop" v-else>未启动 <i class="fas fa-stop"></i></span>
                  </div>
                </div>
                <div class="layout-flexible"></div>
                <div class="layout-inflexible">
                  <ys-button lighting @click="handleUploadClick">
                    <span><i class="fas fa-cloud-upload-alt"></i> 上传新版本</span>
                  </ys-button>
                </div>
              </div>
            </div>
            <div class="padding padding-large" v-if="onlineReference">
              <div class="font-size-small">线上版本</div>
              <div class="layout-horizontal margin-top">
                <div class="layout-flexible">
                  <div class="font-size-medium">
                    <span>当前版本：</span>
                    <span
                        class="font-weight-bold font-size-extra-large font-color-primary">{{
                        onlineReference.majorVersion
                      }}.{{ onlineReference.minorVersion }}</span>
                  </div>
                  <div class="font-color-secondary margin-top-small">
                    上传时间：{{ TimeUtils.formatNormal(onlineReference.time) }}
                  </div>
                  <div class="font-color-secondary margin-top-small" v-if="onlineReference.remark">
                    {{ onlineReference.remark }}
                  </div>
                </div>
                <div class="operations layout-inflexible">
                  <ys-button type="secondary" lighting
                    @click.stop="handleStopPluginReferenceClick()">
                    <span class="font-color-danger"><i class="far fa-stop-circle"></i> 停止</span>
                  </ys-button>
                  <ys-button type="secondary" lighting round class="btn-delete margin-left"
                    @click="handleDeleteReferenceClick(onlineReference)">
                    <span><i class="fas fa-trash-alt"></i></span>
                  </ys-button>
                </div>
              </div>
            </div>
          </div>
          <div class="margin-vertical-large font-weight-bold font-color-secondary">历史版本：</div>
          <div class="card card-reference margin-top-small" v-for="reference in referenceList" :key="reference.id"
               v-show="!onlineReference || onlineReference.id !== reference.id">
            <div class="layout-horizontal">
              <div class="layout-flexible">
                <div class="font-size-medium">
                  <span>版本号：</span>
                  <span
                      class="font-weight-bold font-size-extra-large">{{
                      reference.majorVersion
                    }}.{{ reference.minorVersion }}</span>
                </div>
                <div class="font-color-secondary margin-top-small">上传时间：{{ TimeUtils.formatNormal(reference.time) }}
                </div>
                <div class="font-color-secondary margin-top-small" v-if="reference.remark">{{ reference.remark }}</div>
              </div>
              <div class="operations layout-inflexible">
                <ys-button lighting @click.stop="handleStartPluginReferenceClick(reference)">
                  <span><i class="far fa-play-circle"></i> 启动</span>
                </ys-button>
                <ys-button lighting round type="secondary" class="btn-delete margin-left"
                  @click="handleDeleteReferenceClick(reference)">
                  <span><i class="fas fa-trash-alt"></i></span>
                </ys-button>
              </div>
            </div>
          </div>
        </div>
      </ys-infinite-scroll-view>
      <div class="loading-container" v-if="loadingCode === busyLoadingCode">
        <div class="loading">
          <i class="el-icon-loading"></i>
        </div>
      </div>
    </div>
    <delete-dialog
        v-if="onlineReference && pickedReference"
        :visible.sync="startReferenceDialogVisible"
        :message="`你确定要启动${pickedReference.majorVersion}.${pickedReference.minorVersion}来替代当前线上版本${onlineReference.majorVersion}.${onlineReference.minorVersion}吗？`"
        confirm-button-text="启动"
        @confirm="handleStartPluginReferenceConfirm"
    ></delete-dialog>
    <delete-dialog
        :visible.sync="stopReferenceDialogVisible"
        message="您确定要停止该插件吗？"
        confirm-button-text="停止"
        @confirm="handleStopPluginReferenceConfirm"
    ></delete-dialog>
    <delete-dialog
      v-if="pickedReference"
      :visible.sync="deleteRemindDialogVisible"
      :message="`您确定要删除${pickedReference.majorVersion}.${pickedReference.minorVersion}吗？`"
      @confirm="handleDeleteReferenceConfirm"
    ></delete-dialog>
    <plugin-upload-dialog
        :visible.sync="pluginUploadDialogVisible"
        :plugin-id="pluginId"
        @confirm="handleUploadConfirm"
    ></plugin-upload-dialog>
  </div>
</template>

<script>
import BackButton from "@/components/backbutton/BackButton";
import httpapi from "@/assets/javascript/httpapi";
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import YsButton from "@/components/wedigets/YsButton";
import {TimeUtils} from "@/assets/javascript/time-utils";
import DeleteDialog from "@/components/dialog/DeleteDialog";
import PluginUploadDialog from "@/pages/plugin/dialog/PluginUploadDialog";

function doLoadPlugin(id) {
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/plugin/module/get',
    data: {
      id,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    this.plugin = res.data;
    this.onlineReference = this.plugin.online;
    this.busyLoadingCode = 0;
    if(this.referenceList.length === 0) {
      doLoadPluginReferenceList.bind(this)(1);
    } else {
      let isLastReferenceLoaded = false;
      if(this.plugin.last) {
        for (let reference of this.referenceList) {
          if (reference.id === this.plugin.last.id) {
            isLastReferenceLoaded = true;
            break;
          }
        }

        if(!isLastReferenceLoaded) {
          this.$appendBefore(this.referenceList, [this.plugin.last]);
        }
      }
    }
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doLoadPluginReferenceList(pageNum) {
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/plugin/module/reference/list/get/by_tag',
    data: {
      tag: this.plugin.tag,
      pageNum,
      pageSize: 20,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    this.$appendAfter(this.referenceList, res.data.list);
    this.pageNum = pageNum;
    this.totalPages = res.data.pages;
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode != this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doLoadPunginReference(id) {
  if(this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/plugin/module/reference/get',
    data: {
      id,
    }
  }).then(res=>{
    if(loadingCode !== this.loadingCode) return;
    this.$appendBefore(this.referenceList, res.data);
    if(res.data.id === this.onlineReference?.id) {
      this.onlineReference = res.data;
    }
    if(res.data.id === this.plugin?.online?.id) {
      this.plugin.online = res.data;
    }
    if(res.data.id === this.plugin?.last?.id) {
      this.plugin.last = res.data;
    }
  }).catch(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doStartPluginReference(reference) {
  if(this.loadingCode === this.busyLoadingCode) {
    this.$showBusy();
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqPost({
    path: '/plugin/module/reference/set/online',
    data: {
      id: reference.id,
    }
  }).then(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.onlineReference = reference;
    this.plugin.online = reference;
    this.busyLoadingCode = 0;
    doLoadPunginReference.bind(this)(reference.id);
  }).catch(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.$message.error('操作失败');
  }).complete(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doStopPluginReference(reference) {
  if(this.loadingCode === this.busyLoadingCode) {
    this.$showBusy();
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqPost({
    path: '/plugin/module/reference/set/offline',
    data: {
      id: reference.id,
    }
  }).then(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.onlineReference = null;
    this.plugin.online = null;
    this.busyLoadingCode = 0;
    doLoadPunginReference.bind(this)(reference.id);
  }).catch(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.$message.error('操作失败');
  }).complete(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doRemovePluginReference(id) {
  if(this.loadingCode === this.busyLoadingCode) {
    this.$showBusy();
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqPost({
    path: '/plugin/module/reference/remove',
    data: {
      id,
    }
  }).then(()=>{
    if(loadingCode !== this.loadingCode) return;
    for(let n = this.referenceList.length - 1; n >= 0; n--) {
      if(this.referenceList[n].id === id) {
        this.referenceList.splice(n, 1);
        break;
      }
    }
    if(this.onlineReference?.id === id) {
      this.onlineReference = null;
    }
    if(this.plugin?.online?.id === id) {
      this.plugin.online = null;
    }
    if(this.plugin?.last?.id === id) {
      this.loadPlugin();
    }
  }).catch(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.$message.error('操作失败');
  }).complete(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

export default {
  name: "Plugin",
  mixins: [httpapi],
  components: {PluginUploadDialog, DeleteDialog, YsButton, YsInfiniteScrollView, BackButton},
  data() {
    return {
      TimeUtils,

      loadingCode: 1,
      busyLoadingCode: 0,

      pluginId: null,

      plugin: null,

      onlineReference: null,

      referenceList: [],
      pageNum: 1,
      totalPages: 0,

      pluginUploadDialogVisible: false,


      pickedReference: null,
      startReferenceDialogVisible: false,

      stopReferenceDialogVisible: false,

      deleteRemindDialogVisible: false,
    }
  },
  activated() {
    if (this.pluginId !== this.$route.query.id) {
      this.pluginId = this.$route.query.id;
      this.loadingCode++;
      this.plugin = null;
      this.onlineReference = null;
      this.referenceList = [];
      this.pageNum = 1;
      this.totalPages = 0;
      if (this.pluginId) {
        this.loadPlugin();
      }
    }

  },
  methods: {
    loadPluginReferenceList(pageNum = 1) {
      doLoadPluginReferenceList.bind(this)(pageNum);
    },
    loadMore() {
      if (this.pageNum < this.totalPages) {
        this.loadPluginReferenceList(this.pageNum + 1);
      }
    },
    loadPlugin() {
      doLoadPlugin.bind(this)(this.pluginId);
    },
    handleUploadClick() {
      this.pluginUploadDialogVisible = true;
    },
    handleUploadConfirm() {
      this.loadPlugin();
    },
    handleStartPluginReferenceClick(reference) {
      if(this.onlineReference) {
        this.pickedReference = reference;
        this.startReferenceDialogVisible = true;
      } else {
        doStartPluginReference.bind(this)(reference);
      }
    },
    handleStartPluginReferenceConfirm() {
      let reference = this.pickedReference;
      if(reference) {
        doStartPluginReference.bind(this)(reference);
      }
    },
    handleStopPluginReferenceClick() {
      this.stopReferenceDialogVisible = true;
    },
    handleStopPluginReferenceConfirm() {
      let reference = this.onlineReference;
      if(reference) {
        doStopPluginReference.bind(this)(reference);
      }
    },
    handleDeleteReferenceClick(reference) {
      this.pickedReference = reference;
      this.deleteRemindDialogVisible = true;
    },
    handleDeleteReferenceConfirm() {
      let reference = this.pickedReference;
      if(reference) {
        doRemovePluginReference.bind(this)(reference.id);
      }
    }
  }
}
</script>

<style scoped>

.card {
  background-color: white;
  border-radius: 16px;
  padding: 20px;
  box-shadow: none;
  border: 1px solid #dadada;
  max-width: 1200px;
  overflow: hidden;
}

.plugin-icon {
  width: 32px;
  height: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f091a6;
  border-radius: 50%;
  color: white;
  box-shadow: 0 0 2px #f091a6;
}

.mark-state {
  padding: 2px 8px;
  line-height: 14px;
  border-radius: 2px;
}

.mark-state.mark-start {
  background-color: #f091a6;
  color: white;
  box-shadow: 0 0 4px #f091a6;
}

.mark-state.mark-stop {
  background-color: #f1f1f1;
  color: #7d7c7c;
}

.reference-icon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #f1f1f1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card-reference .operations {
  visibility: hidden;
}

.card-reference:hover .operations {
  visibility: visible;
}

.loading-container {
  height: 0px;
}

.loading-container .loading {
  width: 32px;
  height: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 4px #bec0c0;
  position: relative;
  top: -48px;
}

.btn-delete {
  color: #7d7c7c;
}

.btn-delete:hover {
  color: #ff5500;
}

</style>